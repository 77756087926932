import React from 'react';
import { Phone } from 'lucide-react';
import type { Product } from '../types/product';
import { CONTACT_PHONE } from '../config/constants';

interface PriceTableProps {
  products: Product[];
}

export function PriceTable({ products }: PriceTableProps) {
  const handleCall = () => {
    window.location.href = `tel:${CONTACT_PHONE}`;
  };

  return (
    <div className="overflow-x-auto">
      <div className="grid gap-4 sm:hidden">
        {products.map((product, index) => (
          <div 
            key={`mobile-${product.name}-${index}`}
            className={`p-4 rounded-lg ${
              index % 2 === 0 ? 'bg-white' : 'bg-[#496c2f]/5'
            }`}
          >
            <div className="flex flex-col mb-2">
              <button
                onClick={handleCall}
                className="text-left font-medium text-[#496c2f] hover:text-[#f3ab1c] transition-colors"
              >
                {product.name}
              </button>
              {product.localName && (
                <span className="text-sm text-gray-500">
                  {product.localName}
                </span>
              )}
            </div>
            <div className="flex justify-between items-center">
              <div>
                <span className="text-[#496c2f] font-bold">
                  ₹{product.price.toLocaleString()}
                </span>
                <span className="text-sm text-gray-500 ml-1">
                  /{product.unit || 'kg'} (min {product.minQuantity}{product.unit || 'kg'})
                </span>
              </div>
              <button
                onClick={handleCall}
                className="bg-[#496c2f] text-white px-4 py-2 rounded-lg hover:bg-[#f3ab1c] transition-colors flex items-center gap-2"
              >
                <Phone className="h-4 w-4" />
                Buy
              </button>
            </div>
          </div>
        ))}
      </div>

      <table className="w-full border-collapse hidden sm:table">
        <thead>
          <tr className="bg-[#496c2f] text-white">
            <th className="px-6 py-4 text-left">Name</th>
            <th className="px-6 py-4 text-right">Price</th>
            <th className="px-6 py-4 text-right">Min. Quantity</th>
            <th className="px-6 py-4 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr
              key={`desktop-${product.name}-${index}`}
              className={`${
                index % 2 === 0 ? 'bg-white' : 'bg-[#496c2f]/5'
              } hover:bg-[#f3ab1c]/5 transition-colors`}
            >
              <td className="px-6 py-4">
                <button
                  onClick={handleCall}
                  className="text-left"
                >
                  <span className="text-[#496c2f] font-medium hover:text-[#f3ab1c] transition-colors">
                    {product.name}
                  </span>
                  {product.localName && (
                    <span className="text-sm text-gray-500 ml-2">
                      ({product.localName})
                    </span>
                  )}
                </button>
              </td>
              <td className="px-6 py-4 text-right">
                <span className="text-[#496c2f] font-bold">
                  ₹{product.price.toLocaleString()}
                </span>
                <span className="text-gray-500">/{product.unit || 'kg'}</span>
              </td>
              <td className="px-6 py-4 text-right text-gray-600">
                {product.minQuantity}{product.unit || 'kg'}
              </td>
              <td className="px-6 py-4">
                <button 
                  onClick={handleCall}
                  className="mx-auto flex items-center gap-2 bg-[#496c2f] text-white px-4 py-2 rounded-lg hover:bg-[#f3ab1c] transition-colors"
                >
                  <Phone className="h-4 w-4" />
                  Buy
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}