import React from 'react';

export function Logo() {
  return (
    <div className="flex flex-col items-center">
      <div className="relative w-32 h-32">
        {/* Yellow background */}
        <div className="absolute inset-0 bg-[#f3ab1c] rounded-full"></div>
        
        {/* Green circle with plant */}
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-[#496c2f] rounded-full flex items-center justify-center">
          <svg
            viewBox="0 0 100 100"
            className="w-12 h-12 text-white"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M50 80 L50 40" />
            <path d="M50 40 L35 25 M50 40 L65 25" />
            <path d="M50 60 L35 45 M50 60 L65 45" />
            <path d="M50 50 L35 35 M50 50 L65 35" />
          </svg>
        </div>
      </div>
      <h1 className="mt-4 text-3xl font-medium text-[#496c2f]">
        Coimbatore Flowers
      </h1>
    </div>
  );
}