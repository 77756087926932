import React, { useState } from 'react';
import { Phone } from 'lucide-react';
import { PriceTable } from './components/PriceTable';
import { AdminPanel } from './components/AdminPanel';
import { Logo } from './components/Logo';
import { useProducts } from './hooks/useProducts';
import { CONTACT_PHONE } from './config/constants';

function App() {
  const [showAdminLogin, setShowAdminLogin] = useState(false);
  const { 
    products: flowers, 
    loading: loadingFlowers, 
    error: flowersError,
    updateProducts: updateFlowers 
  } = useProducts('flowers');
  
  const { 
    products: leaves, 
    loading: loadingLeaves, 
    error: leavesError,
    updateProducts: updateLeaves 
  } = useProducts('leaves');

  if (loadingFlowers || loadingLeaves) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-[#496c2f] text-xl">Loading...</div>
      </div>
    );
  }

  if (flowersError || leavesError) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-xl">
          {flowersError || leavesError}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#496c2f]/5 to-[#f3ab1c]/5">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <Logo />
          <p className="text-xl text-[#496c2f] mt-4">Today's Price List</p>
          <button
            onClick={() => setShowAdminLogin(true)}
            className="mt-4 text-sm text-gray-500 hover:text-[#496c2f] transition-colors"
          >
            Admin
          </button>
          
          {/* Price notices in English and Tamil */}
          <div className="mt-4 space-y-1 text-xs text-[#496c2f]">
            <p>* Prices are excluding delivery charges and subject to change anytime</p>
            <p className="font-tamil">* விலைகள் எப்போது வேண்டுமானாலும் மாற்றப்படலாம், விநியோக கட்டணம் தனி</p>
          </div>
        </div>

        <div className="space-y-8">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <h2 className="bg-[#496c2f] text-white px-6 py-3 text-xl font-semibold">Fresh Flowers</h2>
            <div className="p-4">
              {showAdminLogin && (
                <AdminPanel 
                  products={flowers} 
                  onUpdatePrices={updateFlowers}
                  category="flowers"
                  onClose={() => setShowAdminLogin(false)}
                />
              )}
              <PriceTable products={flowers} />
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <h2 className="bg-[#496c2f] text-white px-6 py-3 text-xl font-semibold">Fresh Leaves</h2>
            <div className="p-4">
              {showAdminLogin && (
                <AdminPanel 
                  products={leaves} 
                  onUpdatePrices={updateLeaves}
                  category="leaves"
                  onClose={() => setShowAdminLogin(false)}
                />
              )}
              <PriceTable products={leaves} />
            </div>
          </div>
        </div>

        <footer className="mt-16 text-center text-gray-600 border-t border-gray-200 pt-8">
          <p className="text-sm text-gray-500 mb-4">Click below to call now and place your order</p>
          <div className="mt-4">
            <a 
              href={`tel:${CONTACT_PHONE}`}
              className="inline-flex items-center gap-2 bg-[#496c2f] text-white px-6 py-3 rounded-lg hover:bg-[#f3ab1c] transition-colors text-lg font-medium"
            >
              <Phone className="h-5 w-5" />
              Buy
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;