import { useState, useEffect } from 'react';
import { ref, onValue, set } from 'firebase/database';
import type { Product } from '../types/product';
import { database } from '../config/firebase';
import { flowers as initialFlowers, leaves as initialLeaves } from '../data/flowers';

export function useProducts(category: 'flowers' | 'leaves') {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const productsRef = ref(database, `products/${category}`);
    
    const unsubscribe = onValue(productsRef, (snapshot) => {
      setLoading(true);
      try {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const productsArray = Array.isArray(data) ? data : Object.values(data);
          setProducts(productsArray);
        } else {
          // Initialize with default data if none exists
          const initialData = category === 'flowers' ? initialFlowers : initialLeaves;
          set(productsRef, initialData);
          setProducts(initialData);
        }
        setError(null);
      } catch (err) {
        console.error(`Error loading ${category}:`, err);
        setError(`Failed to load ${category}`);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [category]);

  const updateProducts = async (newProducts: Product[]) => {
    setLoading(true);
    setError(null);
    
    try {
      const productsRef = ref(database, `products/${category}`);
      await set(productsRef, newProducts);
      setProducts(newProducts);
    } catch (error) {
      console.error(`Error updating ${category}:`, error);
      setError(`Failed to update ${category}`);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { products, loading, error, updateProducts };
}