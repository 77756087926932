import React, { useState } from 'react';
import { Plus, X } from 'lucide-react';
import type { Product } from '../types/product';
import { ProductList } from './ProductList';
import { useAuth } from '../contexts/AuthContext';

interface AdminPanelProps {
  products: Product[];
  onUpdatePrices: (updatedProducts: Product[]) => void;
  category: 'flowers' | 'leaves';
  onClose: () => void;
}

export function AdminPanel({ products, onUpdatePrices, category, onClose }: AdminPanelProps) {
  const { login, isAdmin } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState<'price' | 'manage' | null>(null);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [editableProducts, setEditableProducts] = useState(products.map(p => ({
    ...p,
    unit: p.unit || 'kg'
  })));

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    
    try {
      await login(password);
      setIsOpen(true);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePriceChange = (index: number, price: string) => {
    const updatedProducts = [...editableProducts];
    updatedProducts[index] = {
      ...updatedProducts[index],
      price: price === '' ? 0 : Number(price)
    };
    setEditableProducts(updatedProducts);
  };

  const handleNameChange = (index: number, name: string) => {
    const updatedProducts = [...editableProducts];
    updatedProducts[index] = {
      ...updatedProducts[index],
      name
    };
    setEditableProducts(updatedProducts);
  };

  const handleLocalNameChange = (index: number, localName: string) => {
    const updatedProducts = [...editableProducts];
    updatedProducts[index] = {
      ...updatedProducts[index],
      localName
    };
    setEditableProducts(updatedProducts);
  };

  const handleQuantityChange = (index: number, quantity: string) => {
    const updatedProducts = [...editableProducts];
    updatedProducts[index] = {
      ...updatedProducts[index],
      minQuantity: quantity === '' ? 0 : Number(quantity)
    };
    setEditableProducts(updatedProducts);
  };

  const handleUnitChange = (index: number, unit: 'kg' | 'Nos') => {
    const updatedProducts = [...editableProducts];
    updatedProducts[index] = {
      ...updatedProducts[index],
      unit
    };
    setEditableProducts(updatedProducts);
  };

  const handleDelete = (index: number) => {
    setEditableProducts(products => products.filter((_, i) => i !== index));
  };

  const handleCancel = () => {
    setEditableProducts(products);
    setMode(null);
  };

  const handleSave = () => {
    onUpdatePrices(editableProducts);
    setMode(null);
  };

  const handleAddProduct = () => {
    const newProduct: Product = {
      name: '',
      localName: '',
      price: 0,
      minQuantity: 10,
      unit: 'kg'
    };
    setEditableProducts([newProduct, ...editableProducts]);
  };

  const handleReorder = (reorderedItems: Product[]) => {
    setEditableProducts(reorderedItems);
  };

  if (!isOpen) {
    return (
      <div className="mt-4 p-4 border rounded-lg bg-gray-50">
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <input
              type="password"
              placeholder="Admin Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="flex gap-2 justify-center">
            <button
              type="submit"
              disabled={loading}
              className="bg-[#496c2f] text-white px-4 py-2 rounded hover:bg-[#f3ab1c] transition-colors"
            >
              {loading ? 'Logging in...' : 'Login'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
          </div>
        </form>
        {error && (
          <p className="mt-4 text-red-500 text-sm">{error}</p>
        )}
      </div>
    );
  }

  const ActionButtons = () => (
    <div className="flex justify-end gap-2">
      <button
        onClick={handleCancel}
        className="px-4 py-2 text-gray-600 hover:text-gray-800"
      >
        Cancel
      </button>
      <button
        onClick={handleSave}
        className="bg-[#496c2f] text-white px-4 py-2 rounded hover:bg-[#f3ab1c] transition-colors"
      >
        Save Changes
      </button>
    </div>
  );

  return (
    <div className="mb-4 p-4 border rounded-lg bg-gray-50">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
        <div className="w-full sm:w-auto grid grid-cols-2 sm:flex gap-2">
          <button
            onClick={() => setMode('price')}
            className={`px-4 py-2 rounded text-center ${
              mode === 'price' ? 'bg-[#496c2f] text-white' : 'bg-gray-200'
            }`}
          >
            Update Prices
          </button>
          <button
            onClick={() => setMode('manage')}
            className={`px-4 py-2 rounded text-center ${
              mode === 'manage' ? 'bg-[#496c2f] text-white' : 'bg-gray-200'
            }`}
          >
            Manage Items
          </button>
        </div>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700 self-end sm:self-auto">
          <X className="h-5 w-5" />
        </button>
      </div>

      {mode && (
        <>
          <ActionButtons />

          {mode === 'manage' && (
            <button
              onClick={handleAddProduct}
              className="my-4 bg-[#496c2f] text-white px-4 py-2 rounded flex items-center gap-2 hover:bg-[#f3ab1c] transition-colors"
            >
              <Plus className="h-4 w-4" />
              Add {category === 'flowers' ? 'Flower' : 'Leaf'}
            </button>
          )}

          <ProductList
            items={editableProducts}
            mode={mode}
            onPriceChange={handlePriceChange}
            onNameChange={handleNameChange}
            onLocalNameChange={handleLocalNameChange}
            onQuantityChange={handleQuantityChange}
            onUnitChange={handleUnitChange}
            onDelete={handleDelete}
            onReorder={handleReorder}
            priceInputStyle="w-24 px-2 py-1 border rounded [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          />

          <div className="mt-4">
            <ActionButtons />
          </div>
        </>
      )}
    </div>
  );
}