export interface Product {
  name: string;
  localName?: string;
  price: number;
  minQuantity: number;
}

export const flowers: Product[] = [
  {
    name: "Mullai",
    localName: "முல்லை",
    price: 800,
    minQuantity: 10
  },
  {
    name: "Jaathi",
    localName: "ஜாதி",
    price: 1200,
    minQuantity: 10
  },
  {
    name: "Pitchi",
    localName: "பிச்சி",
    price: 900,
    minQuantity: 10
  },
  {
    name: "Red Rose",
    localName: "செவ்வரளி",
    price: 300,
    minQuantity: 10
  },
  {
    name: "Button Rose",
    localName: "பட்டன் ரோஜா",
    price: 250,
    minQuantity: 10
  },
  {
    name: "Sampangi",
    localName: "சம்பங்கி",
    price: 400,
    minQuantity: 10
  },
  {
    name: "Marigold Orange",
    localName: "சாமந்தி",
    price: 120,
    minQuantity: 10
  },
  {
    name: "Sevvanthi",
    localName: "செவ்வந்தி",
    price: 200,
    minQuantity: 10
  },
  {
    name: "Lotus",
    localName: "தாமரை",
    price: 600,
    minQuantity: 10
  },
  {
    name: "Kanakambaram",
    localName: "கனகாம்பரம்",
    price: 350,
    minQuantity: 10
  },
  {
    name: "Arali",
    localName: "அரளி",
    price: 180,
    minQuantity: 10
  },
  {
    name: "Vadamalli",
    localName: "வடமல்லி",
    price: 280,
    minQuantity: 10
  },
  {
    name: "Malligai",
    localName: "மல்லிகை",
    price: 750,
    minQuantity: 10
  },
  {
    name: "Pavalamalli",
    localName: "பவளமல்லி",
    price: 450,
    minQuantity: 10
  },
  {
    name: "Paneer Rose",
    localName: "பனீர் ரோஜா",
    price: 280,
    minQuantity: 10
  },
  {
    name: "Saamandhi",
    localName: "சாமந்தி",
    price: 180,
    minQuantity: 10
  },
  {
    name: "Thumbai",
    localName: "தும்பை",
    price: 150,
    minQuantity: 10
  },
  {
    name: "Idly Poo",
    localName: "இட்லி பூ",
    price: 200,
    minQuantity: 10
  }
];

export const leaves: Product[] = [
  {
    name: "Vettiver",
    localName: "வெட்டிவேர்",
    price: 100,
    minQuantity: 10
  },
  {
    name: "Mango Leaves",
    localName: "மாவிலை",
    price: 50,
    minQuantity: 10
  },
  {
    name: "Banana Leaves",
    localName: "வாழை இலை",
    price: 80,
    minQuantity: 10
  },
  {
    name: "Betel Leaves",
    localName: "வெற்றிலை",
    price: 150,
    minQuantity: 10
  },
  {
    name: "Neem Leaves",
    localName: "வேப்பிலை",
    price: 60,
    minQuantity: 10
  },
  {
    name: "Tulsi Leaves",
    localName: "துளசி",
    price: 120,
    minQuantity: 10
  },
  {
    name: "Curry Leaves",
    localName: "கறிவேப்பிலை",
    price: 90,
    minQuantity: 10
  }
];