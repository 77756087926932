// Contact Information
export const CONTACT_PHONE = '+919944367779';

// Business Configuration
export const BUSINESS_CONFIG = {
  name: 'Coimbatore Flowers',
  minQuantity: 10,
  defaultUnit: 'kg' as const
} as const;

// Admin Configuration
export const ADMIN_CONFIG = {
  emails: ['coimbatoreflowers.com@gmail.com'],
  phoneNumbers: [CONTACT_PHONE]
} as const;