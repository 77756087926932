import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';
import type { Product } from '../types/product';

interface ProductListProps {
  items: Product[];
  mode: 'price' | 'manage';
  onPriceChange: (index: number, price: string) => void;
  onNameChange: (index: number, name: string) => void;
  onLocalNameChange: (index: number, name: string) => void;
  onQuantityChange: (index: number, quantity: string) => void;
  onUnitChange: (index: number, unit: 'kg' | 'Nos') => void;
  onDelete: (index: number) => void;
  onReorder?: (items: Product[]) => void;
  priceInputStyle: string;
}

export function ProductList({
  items,
  mode,
  onPriceChange,
  onNameChange,
  onLocalNameChange,
  onQuantityChange,
  onUnitChange,
  onDelete,
  onReorder,
  priceInputStyle
}: ProductListProps) {
  const [editingOrder, setEditingOrder] = useState<number | null>(null);
  const [orderNumber, setOrderNumber] = useState<string>('');

  const handleOrderChange = (index: number, newOrder: string) => {
    const order = parseInt(newOrder);
    if (!isNaN(order) && order > 0 && order <= items.length && onReorder) {
      const newItems = [...items];
      const item = newItems.splice(index, 1)[0];
      newItems.splice(order - 1, 0, item);
      onReorder(newItems);
      setEditingOrder(null);
      setOrderNumber('');
    }
  };

  return (
    <div className="space-y-4">
      {items.map((product, index) => (
        <div key={index} className="flex items-center gap-4 bg-white p-3 rounded group">
          {mode === 'manage' && (
            <div className="w-16 flex items-center">
              {editingOrder === index ? (
                <input
                  type="text"
                  value={orderNumber}
                  onChange={(e) => setOrderNumber(e.target.value)}
                  onBlur={() => {
                    handleOrderChange(index, orderNumber);
                    setEditingOrder(null);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleOrderChange(index, orderNumber);
                      setEditingOrder(null);
                    }
                  }}
                  className="w-12 px-2 py-1 border rounded text-center"
                  autoFocus
                />
              ) : (
                <span
                  onClick={() => {
                    setEditingOrder(index);
                    setOrderNumber((index + 1).toString());
                  }}
                  className="cursor-pointer hover:text-[#496c2f]"
                >
                  #{index + 1}
                </span>
              )}
            </div>
          )}
          
          {mode === 'price' ? (
            <div className="flex-1 flex items-center gap-6">
              <div className="flex-1">
                <div className="font-medium">{product.name}</div>
                {product.localName && (
                  <div className="text-sm text-gray-500">{product.localName}</div>
                )}
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={product.price || ''}
                  onChange={(e) => onPriceChange(index, e.target.value)}
                  className={priceInputStyle}
                />
                <span className="text-gray-500">₹/{product.unit || 'kg'}</span>
              </div>
            </div>
          ) : (
            <div className="flex-1 grid grid-cols-1 md:grid-cols-5 gap-4">
              <input
                type="text"
                value={product.name}
                onChange={(e) => onNameChange(index, e.target.value)}
                placeholder="English Name"
                className="px-2 py-1 border rounded"
              />
              <input
                type="text"
                value={product.localName || ''}
                onChange={(e) => onLocalNameChange(index, e.target.value)}
                placeholder="Tamil Name"
                className="px-2 py-1 border rounded"
              />
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={product.price || ''}
                  onChange={(e) => onPriceChange(index, e.target.value)}
                  className={priceInputStyle}
                />
                <span className="text-gray-500">₹/{product.unit || 'kg'}</span>
              </div>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={product.minQuantity}
                  onChange={(e) => onQuantityChange(index, e.target.value)}
                  className="w-24 px-2 py-1 border rounded"
                />
                <select
                  value={product.unit || 'kg'}
                  onChange={(e) => onUnitChange(index, e.target.value as 'kg' | 'Nos')}
                  className="px-2 py-1 border rounded"
                >
                  <option value="kg">kg</option>
                  <option value="Nos">Nos</option>
                </select>
              </div>
            </div>
          )}
          
          {mode === 'manage' && (
            <button
              onClick={() => onDelete(index)}
              className="text-red-500 hover:text-red-700 p-1"
              title="Delete"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          )}
        </div>
      ))}
    </div>
  );
}